.custom-chip2 {
  cursor: pointer;
  display: inline-block;
  padding: 4px 20px;
  margin: 0px;
  border-radius: 8px;
  border: 1px solid black;
  transition: all 0.3s;
  user-select: none;
  margin-right: 5px;
  margin-bottom: 5px;
  font-size: 14px;
  color: black;
  background-color: white;
}

.custom-chip2.selected {
  background-color: #ebd0ff;
  color: black;
  border-color: black;
}
