@keyframes scroll {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%); /* This should be the height of a single card */
  }
}

.scrolling-cards {
  animation: scroll 90s linear infinite; /* Adjust the time as necessary */
}


@keyframes scrollHorizontal {
  0% {
    transform: translateX(0);
  }
  100% {
    /* This should be the width of a single card times the number of cards */
    transform: translateX(calc(-1 * var(--card-width) * var(--num-cards)));
  }
}

@keyframes scrollHorizontal {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-2500px); /* 10 cards * 250px per card */
  }
}


.scrolling-cards-horizontal {
  display: flex;
  flex-direction: row;
  animation: scrollHorizontal 60s linear infinite; /* Duration of the animation */
  white-space: nowrap; /* Prevent wrapping of inline elements */
}

.scrolling-cards-container-horizontal {
  overflow-x: hidden;
  width: 100%; /* Full width of the container */
}