.loading__briefings {
  position: relative;
  min-height: 95vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loading__briefings__animation {
  left: 50%;
  top: 50%;
  font-size: 20px;
  font-family: 'DM Sans', sans-serif;
  font-weight: bold;
  letter-spacing: 1px;
  position: absolute;
  overflow: hidden;
  transform: translate(-50%, -50%);
}

.loading__briefings__animation:before {
  color: #aaa;
  content: attr(data-loading-text);
}

.loading__briefings__animation:after {
  top: 0;
  left: 0;
  width: 0;
  opacity: 1;
  color: #444;
  overflow: hidden;
  position: absolute;
  content: attr(data-loading-text);
  -webkit-animation: loading 5s infinite;
          animation: loading 5s infinite;
}

@-webkit-keyframes loading {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@keyframes loading {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}