.rotating-border {
  background: linear-gradient(90deg, rgb(0, 0, 0) 60%, transparent 10%), linear-gradient(90deg, rgb(0, 0, 0) 60%, transparent 10%), linear-gradient(0deg, rgb(0, 0, 0) 60%, transparent 10%), linear-gradient(0deg, rgb(0, 0, 0) 60%, transparent 10%);
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 5px 1.2px, 5px 1.2px, 1.2px 5px, 1.2px 5px;
  animation: border-dance 20s infinite linear;
  border-radius: 5px;
}

@keyframes border-dance {
  0% {
    background-position: 0 0, 100% 100%, 0 100%, 100% 0;
  }
  100% {
    background-position: 100% 0, 0 100%, 0 0, 100% 100%;
  }
}