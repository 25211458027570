.button-pulse {
	background: black;
  border-radius: 8px;
  color: white;
	height: 24px;
  font-weight: bold;
  font-size: 12px;
	width: auto;
	box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
	transform: scale(1);
	animation: pulse 2s infinite;
}

.pulse {
	transform: scale(1);
	animation: pulse 2s infinite;
}

@keyframes pulse {

	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
  
}

.description-html ol {
  list-style-position: inside;
  padding-left: 1ch;
}