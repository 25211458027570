.connectfacebookbutton {
  border: 1px solid #000000;
  border-radius: 6px;
  font-size: 10px;
  padding: 2px 10px;
  font-family: 'DM Sans', sans-serif;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.connectfacebookbutton svg {
  margin-left: 3px;
  font-size: 14px;
}

.connectfacebookbutton__white {
  background-color: white;
  color: black;
}

.connectfacebookbutton__black {
  background-color: black;
  color: white;
}
