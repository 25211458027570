.custom-chip {
  cursor: pointer;
  display: inline-block;
  padding: 2px 10px;
  margin: 0px;
  border-radius: 25px;
  border: 2px dotted #cccccc;
  transition: all 0.3s;
  user-select: none;
  margin-right: 5px;
  margin-bottom: 5px;
  font-size: 14px;
}

.custom-chip.selected {
  background-color: black;
  color: white;
  border-color: #00bcd4;
}
